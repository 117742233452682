/**
 * @generated SignedSource<<fe0c5460ca3eac5adaf53844a8eb60a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InfectopharmRole = "AlliedHealthProfessionals" | "Apothecary" | "Dentist" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "%future added value";
export type DocCheckLoginInput = {
  brandRef: string;
  clientMutationId?: string | null;
  loginCode: string;
  targetRole: InfectopharmRole;
  uniqueKey: string;
};
export type DocCheckLoginSuccessScreen_LoginMutation$variables = {
  input: DocCheckLoginInput;
};
export type DocCheckLoginSuccessScreen_LoginMutation$data = {
  readonly InfectopharmAuth: {
    readonly docCheckLogin: {
      readonly accessToken: string;
      readonly refreshToken: string;
    } | null;
  };
};
export type DocCheckLoginSuccessScreen_LoginMutation = {
  response: DocCheckLoginSuccessScreen_LoginMutation$data;
  variables: DocCheckLoginSuccessScreen_LoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InfectopharmAuthMutations",
    "kind": "LinkedField",
    "name": "InfectopharmAuth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "DocCheckLoginPayload",
        "kind": "LinkedField",
        "name": "docCheckLogin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accessToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refreshToken",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocCheckLoginSuccessScreen_LoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocCheckLoginSuccessScreen_LoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "26300135ac152763d9bb5add9cbaf3e8",
    "id": null,
    "metadata": {},
    "name": "DocCheckLoginSuccessScreen_LoginMutation",
    "operationKind": "mutation",
    "text": "mutation DocCheckLoginSuccessScreen_LoginMutation(\n  $input: DocCheckLoginInput!\n) {\n  InfectopharmAuth {\n    docCheckLogin(input: $input) {\n      accessToken\n      refreshToken\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e6f0732a306e8f287368a39fc7e2fc1f";

export default node;
