import React from "react";
import { useFormik } from "formik";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
	NewsletterDataStep_ProvidePersonalDataMutation,
	SignupProcessStatus,
} from "../../../../__generated__/NewsletterDataStep_ProvidePersonalDataMutation.graphql";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { Newsletter } from "../model/Newsletter";
import { RegistrationDisplayLocation } from "../../../../__generated__/SignupProcessScreen_Query.graphql";
import { NewsletterDataStep_BrandFragment$key } from "../../../../__generated__/NewsletterDataStep_BrandFragment.graphql";
import { InfectopharmCountry } from "../../../../__generated__/SupplyEfnForm_UserFragment.graphql";
import { CustomedCheckbox } from "../../core/components/buttons/CustomedCheckbox";
import { PrimaryButton } from "../../core/components/buttons/PrimaryButton";

const BRAND_FRAGMENT = graphql`
	fragment NewsletterDataStep_BrandFragment on Brand {
		id
		availableRoles
		brandColorHex
	}
`;
const CHECK_EMAIL_MUTATION = graphql`
	mutation NewsletterDataStep_ProvidePersonalDataMutation(
		$input: ProvideNewsletterSignupsInput!
	) {
		Signup {
			provideNewsletterSignups(input: $input) {
				signupProcess {
					id
					status
				}
			}
		}
	}
`;

interface FormState {
	selectedNewsletters?: string[];
}

interface OwnProps {
	signupProcessId: string;
	newsletters: ReadonlyArray<Newsletter>;
	advance: (newStatus: SignupProcessStatus) => void;
	brandFragmentRef: NewsletterDataStep_BrandFragment$key;
}

export const hasNewsletterTheRegistrationLocation = (
	newsletter: Newsletter,
	location: RegistrationDisplayLocation,
): boolean => {
	return newsletter?.registrationDisplayLocations!.includes(location);
};
export const hasNewsletterTheSignupProcessCountry = (
	newsletter: Newsletter,
	country: InfectopharmCountry,
): boolean => {
	return newsletter?.countries!.includes(country);
};
export const NewsletterDataStep = ({
	signupProcessId,
	newsletters,
	advance,
	brandFragmentRef,
}: OwnProps) => {
	const signupProcess = localStorage.getItem("signUp-process");
	const parsedSignupProcess = JSON.parse(signupProcess!);

	const [testAccount, isRunning] =
		useMutation<NewsletterDataStep_ProvidePersonalDataMutation>(CHECK_EMAIL_MUTATION);
	const brand = useFragment<NewsletterDataStep_BrandFragment$key>(
		BRAND_FRAGMENT,
		brandFragmentRef,
	);
	const formik = useFormik<FormState>({
		initialValues: {
			selectedNewsletters: [],
		},
		onSubmit: (values) => {
			testAccount({
				variables: {
					input: {
						id: signupProcessId,
						newslettersRef: values.selectedNewsletters || [],
						brandId: brand.id,
					},
				},
				onCompleted: (result) => {
					advance(result.Signup.provideNewsletterSignups?.signupProcess.status!);
				},
			});
		},
	});

	return (
		<div>
			<h1 className="mb-4">Newsletter</h1>
			<div className="text-sm mb-4">
				<p>
					Bitte melden Sie sich zu den Newslettern an, die Sie interessieren und klicken
					Sie dann auf „Registrierung abschließen“.
				</p>
			</div>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				{newsletters.map((newsletter) => {
					return (
						<div key={newsletter.id}>
							{hasNewsletterTheRegistrationLocation(newsletter, "Registration") &&
								hasNewsletterTheSignupProcessCountry(
									newsletter,
									parsedSignupProcess?.country!,
								) && (
									<ValidatedField<FormState, string[]>
										key={newsletter.id}
										name={"selectedNewsletters"}
										formikConfig={formik}
										component={({ fieldValue, updateField, fieldName }) => {
											const value = fieldValue || [];
											const concatenatedName =
												fieldName + "-" + newsletter.id;
											return (
												<div className="p-col-12">
													<CustomedCheckbox
														id={concatenatedName}
														className="mr-2"
														name={concatenatedName}
														checked={value.includes(newsletter.id)}
														onChange={(e) => {
															const newValue = e.checked
																? [...value, newsletter.id]
																: value.filter(
																		(sId) =>
																			newsletter.id !== sId,
																  );
															updateField(newValue);
														}}
													/>
													<label
														htmlFor={concatenatedName}
														className="p-checkbox-label"
													>
														Ich freue mich den Newsletter{" "}
														<strong>{newsletter.name}</strong> zu
														abonnieren.{" "}
														<small
															dangerouslySetInnerHTML={{
																__html:
																	newsletter.description || "",
															}}
														/>
													</label>
												</div>
											);
										}}
									/>
								)}
						</div>
					);
				})}

				<PrimaryButton
					disabled={isRunning}
					type="submit"
					label="Registrierung abschließen"
					className="p-mt-2"
				/>
			</form>
		</div>
	);
};
