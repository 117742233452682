/**
 * @generated SignedSource<<9721d4960d993e72274b2a6b607d0d70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "%future added value";
export type InfectopharmRole = "AlliedHealthProfessionals" | "Apothecary" | "Dentist" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "%future added value";
export type RoleApplicationStatus = "DocCheck" | "Finished" | "Started" | "%future added value";
export type Salutation = "Divers" | "Mr" | "Mrs" | "%future added value";
export type UserStatus = "Active" | "Inactive" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DashboardScreen_UserStatusFragment$data = {
  readonly firstName: string;
  readonly id: string;
  readonly isLocked: boolean;
  readonly lastName: string;
  readonly roleApplicationProcess: {
    readonly privateAddress: {
      readonly city: string;
      readonly country: InfectopharmCountry;
      readonly postalCode: string;
      readonly street: string;
    } | null;
    readonly status: RoleApplicationStatus;
    readonly targetRole: InfectopharmRole;
    readonly userStatus: UserStatus;
  } | null;
  readonly salutation: Salutation;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"AccessToBrands_UserStatusFragment" | "AuthScreenBase_UserInformationFragment" | "ChangeEmailForm_UserFragment" | "ChangePasswordForm_UserFragment" | "ChangePrivateAddressForm_UserFragment" | "DeleteMyselfButton_UserFragment" | "Newsletters_UserStatusFragment" | "RolesDisplay_UserStatusFragment" | "SupplyEfnForm_UserFragment">;
  readonly " $fragmentType": "DashboardScreen_UserStatusFragment";
};
export type DashboardScreen_UserStatusFragment$key = {
  readonly " $data"?: DashboardScreen_UserStatusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardScreen_UserStatusFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardScreen_UserStatusFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleApplicationProcess",
      "kind": "LinkedField",
      "name": "roleApplicationProcess",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "targetRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "privateAddress",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RolesDisplay_UserStatusFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessToBrands_UserStatusFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Newsletters_UserStatusFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SupplyEfnForm_UserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangePasswordForm_UserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeEmailForm_UserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangePrivateAddressForm_UserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteMyselfButton_UserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthScreenBase_UserInformationFragment"
    }
  ],
  "type": "UserInformation",
  "abstractKey": null
};

(node as any).hash = "ae7055d9089a722122070dcbf224edf9";

export default node;
