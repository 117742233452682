import * as Yup from "yup";
import { InfectopharmRole } from "../../../../__generated__/SupplyEfnForm_UserFragment.graphql";

export const AustriaTargetRoles: InfectopharmRole[] = [
	"Doctor",
	"Dentist",
	"Apothecary",
	"Midwife",
	"PTA",
];

export const AddressValidationHelper = {
	company: Yup.string().required("Praxis / Institution wird benötigt"),
	street: Yup.string().required("Straße wird benötigt"),
	postalCode: Yup.string().when("country", (country) => {
		return Yup.string()
			.length(country === "Deutschland" ? 5 : 4, `Bitte geben Sie eine gültige PLZ ein.`)
			.matches(/^\d+$/, `Bitte geben Sie eine gültige PLZ ein.`)
			.required("PLZ wird benötigt.");
	}),
	city: Yup.string().required("Stadt wird benötigt"),
};

export const EfnValidationHelper = Yup.string().when("targetRole", (targetRole, schema) => {
	if (targetRole == "Doctor") {
		return Yup.string().when("country", (country, schema) => {
			if (country === "Deutschland") {
				return Yup.string()
					.min(15, `Bitte geben Sie eine gültige EFN-Nummer ein.`)
					.notRequired();
			} else if (country === "Oesterreich") {
				return Yup.string()
					.matches(
						/^\d{3,}-\d{2}$/,
						`Bitte tragen Sie hier Ihre ÖÄK-Arztnummer im Format "3-6 Ziffern - Bindestrich - 2 Ziffern" (z.B. 12345-67) und ohne führenden Buchstaben ein. Sie benötigen diese, um auf der Lernplattform zertifizierte Module bearbeiten zu können.`,
					)
					.notRequired();
			}

			return schema;
		});
	}

	return schema;
});
