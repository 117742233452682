import { RenderConfig } from "./ValidatedField";
import {
	AutoComplete,
	AutoCompleteChangeEvent,
	AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import { useState } from "react";
import styled from "styled-components";
import { BrandColorProps } from "../../../signup/SignUpProcessDialog";
import { calculateButtonStateColor } from "../../../../helpers/calculateButtonStateColor";
import { Speciality } from "../../../signup/helpers/specialityHelper";

export function SpecialityAutoCompleteTextInput({
	items,
	renderConfig: { fieldValue, updateField },
	brandColor,
}: {
	items: Speciality[];
	renderConfig: RenderConfig<string>;
	brandColor?: string;
}) {
	const [suggestions, setSuggestions] = useState<string[]>([]);
	const { hoverColor, pressColor } = calculateButtonStateColor(brandColor);

	const search = (event: AutoCompleteCompleteEvent) => {
		setSuggestions(
			items
				.map((e) => e.label)
				.filter((e: string) => e.toLowerCase().includes(event.query.toLowerCase())),
		);
	};

	const extractValueFromFieldValue = (fieldValue: string | undefined): string => {
		const speciality = items.find((item) => item.value === fieldValue);

		if (speciality) {
			return speciality.label;
		} else {
			return fieldValue || "";
		}
	};

	const handleChange = (event: AutoCompleteChangeEvent) => {
		const value: Speciality | undefined = items.find((item) => item.label === event.value);

		if (value) {
			updateField(value.value);
		} else {
			updateField(event.value);
		}
	};

	return (
		<StyledAutoComplete
			placeholder={"Bitte wählen ..."}
			brandColor={brandColor}
			colorHover={hoverColor}
			colorPress={pressColor}
			value={extractValueFromFieldValue(fieldValue)}
			suggestions={suggestions}
			completeMethod={search}
			onChange={handleChange}
			forceSelection
			dropdown
		/>
	);
}

const StyledAutoComplete = styled(AutoComplete)<BrandColorProps>`
	.p-button {
		background-color: ${(props) => props.brandColor};
	}
	.p-button:hover {
		border-color: ${(props) => props.colorPress} !important;
		background-color: ${(props) => props.colorPress} !important;
	}
	.p-button:active {
		background-color: ${(props) => props.colorHover} !important;
		border-color: ${(props) => props.colorHover} !important;
	}
`;
