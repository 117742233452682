export const RoleTranslations: { [key: string]: string } = {
	Apothecary: "Apothekerin/Apotheker",
	Doctor: "Ärztin/Arzt",
	Dentist: "Zahnärztin/Zahnarzt",
	AlliedHealthProfessionals: "Angehöriger anderer Heilberufe",
	Midwife: "Hebamme",
	PTA: "PTA",
	PharmaceuticalEngineer: "Pharmazieingenieurin/Pharmazieingenieur",
	Other: "Andere Fachkreise",
};

export const ApplicationProcessStatusTranslations: { [key: string]: string } = {
	Started: "Gestartet",
	Finished: "Abgeschlossen",
	InProgress: "Beantragt",
};

export const UserStatusTranslations: { [key: string]: string } = {
	Active: "Aktiv",
	Inactive: "Inaktiv",
};

export const UserMetaTranslations: { [key: string]: string } = {
	registeredOverBrand: "Marke",
	title: "Titel",
	firstName: "Vorname",
	lastName: "Nachname",
	salutation: "Anrede",
	markedForDeletionTimestamp: "Zur löschung markiert am",
};

export const SalutationTranslations: { [key: string]: string } = {
	Mr: "Herr",
	Mrs: "Frau",
	Divers: "Divers",
	Unknown: "Unbekannt",
};

export const UserRoleTranslations: { [key: string]: string } = {
	admin: "Administrator",
	user: "Benutzer",
	support: "Support",
	readonly: "Lesezugriff",
};
