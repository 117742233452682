import tinycolor from "tinycolor2";
export const wissenwirktBrandColor = "#4BA540";
export const calculateButtonStateColor = (brandColor?: string) => {
	const baseColor = brandColor || wissenwirktBrandColor;

	const hoverColor = tinycolor(baseColor).darken(3.5).toString();

	const pressColor = tinycolor(baseColor).darken(15).toString();

	return { hoverColor, pressColor, baseColor };
};
