import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { ActivationScreen_ActivationMutation } from "../../__generated__/ActivationScreen_ActivationMutation.graphql";
import { NonAuthScreenBase } from "../components/core/components/base/NonAuthScreenBase";
import { useMatch, useNavigate } from "react-router-dom";
import { ActivationScreen_Query } from "../../__generated__/ActivationScreen_Query.graphql";
import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Message } from "primereact/message";
import { ACTIVATION_PATH, ACTIVATION_PATH_WITHOUT_BRAND } from "../routes/NoLoginAuthModuleRoutes";
import { ResendEmailValidation } from "../components/signup/ResendEmailValidation";
import { Button } from "primereact/button";
import { InfectopharmRole } from "../../__generated__/SupplyEfnForm_UserFragment.graphql";

const QUERY = graphql`
	query ActivationScreen_Query($brandId: ID!, $skip: Boolean!) {
		node(id: $brandId) @skip(if: $skip) {
			... on Brand {
				id
				brandUrl
				...NonAuthScreenBase_BrandFragment
			}
		}
	}
`;

const ACTIVATION_MUTATION = graphql`
	mutation ActivationScreen_ActivationMutation($input: FinalizeSignupProcessInput!) {
		Signup {
			finalizeSignupProcess(input: $input) {
				signupProcess {
					id
					status
					tokenStatus
					targetRole
				}
			}
		}
	}
`;
type ActivationState = "unknown" | "succeeded" | "error";

export const ActivationScreen = () => {
	const match = useMatch(ACTIVATION_PATH);
	const matchWithoutBrand = useMatch(ACTIVATION_PATH_WITHOUT_BRAND);

	const token = match?.params.token || matchWithoutBrand?.params.token;

	const query = useLazyLoadQuery<ActivationScreen_Query>(QUERY, {
		brandId: match?.params["brandId"] || "",
		skip: !match?.params["brandId"],
	});

	const navigate = useNavigate();

	const [state, setState] = useState<ActivationState>("unknown");

	const [successMessage, setSuccessMessage] = useState<String>();
	const [needsResendButton, setNeedsResendButton] = useState<Boolean>(false);
	const [signupProcessId, setSignupProcessId] = useState<string>("");

	const [activate, isInFlight] =
		useMutation<ActivationScreen_ActivationMutation>(ACTIVATION_MUTATION);

	const SUCCESS_MESSAGE_FINISHED = (
		targetRole: InfectopharmRole,
	) => `Ihr Konto wurde erfolgreich aktiviert.
	Ihre Daten werden jetzt von uns geprüft. Diese Prüfung kann ${
		targetRole == "Doctor" ? `5-10 Werktage` : "24 Stunden"
	} in Anspruch nehmen.
	Nach erfolgreicher Prüfung erhalten Sie eine E-Mail.`;

	const ALREADY_USED_MESSAGE = "Der Aktivierungslink wurde bereits benutzt.";

	const EXPIRED_TOKEN_MESSAGE =
		"Der Aktivierunslink ist abgelaufen (7 Tage). Bitte fordern Sie einen neuen an";

	let validationMutationCalled = false;

	useEffect(() => {
		if (token && !validationMutationCalled) {
			validationMutationCalled = true;

			activate({
				variables: { input: { token: token } },
				onCompleted: (response) => {
					setState("succeeded");
					const status = response.Signup.finalizeSignupProcess?.signupProcess.tokenStatus;
					setSignupProcessId(response.Signup.finalizeSignupProcess?.signupProcess.id!);
					if (status == "Valid") {
						setSuccessMessage(
							SUCCESS_MESSAGE_FINISHED(
								response.Signup.finalizeSignupProcess?.signupProcess.targetRole ||
									"Other",
							),
						);
						setNeedsResendButton(false);
					} else if (status == "Used") {
						setSuccessMessage(ALREADY_USED_MESSAGE);
						setNeedsResendButton(false);
					} else if (status == "Expired") {
						setState("error");
						setSuccessMessage(EXPIRED_TOKEN_MESSAGE);
						setNeedsResendButton(true);
					}
				},
				onError: () => setState("error"),
			});
		}
		// eslint-disable-next-line
	}, []);

	if (!token) {
		return (
			<NonAuthScreenBase>
				<h1 className="mb-4">Konto wird aktiviert</h1>
				<Message severity="error" text="Dieser Link ist nicht gültig." />
			</NonAuthScreenBase>
		);
	}

	return (
		<NonAuthScreenBase brandFragmentRef={query.node || null}>
			<h1>Konto wird aktiviert</h1>
			{isInFlight ? (
				<div className="flex justify-content-center">
					<ProgressSpinner />
				</div>
			) : (
				<div>
					{state === "succeeded" && (
						<Message
							severity={"success"}
							content={<div className="block">{successMessage}</div>}
						></Message>
					)}
					{needsResendButton && (
						<ResendEmailValidation
							brandId={query.node?.id!}
							signupProcessId={signupProcessId}
						/>
					)}
					{state === "error" && !needsResendButton && (
						<>
							<Message
								severity={"error"}
								content={
									<>
										Ihr Konto konnte nicht aktiviert werden. Bitte überprüfen
										Sie den Link dem Sie gefolgt sind.
									</>
								}
							></Message>

							<Button
								label={"Zurück zur Startseite"}
								className="mt-2 mb-auto"
								onClick={() => {
									navigate(`/login/${match?.params["brandId"]}`);
								}}
							/>
						</>
					)}
				</div>
			)}
		</NonAuthScreenBase>
	);
};
