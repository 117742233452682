import React from "react";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Dropdown } from "primereact/dropdown";
import { InfectopharmRole } from "../../../../__generated__/RolesDisplay_UserStatusFragment.graphql";
import { Message } from "primereact/message";
import { RoleTranslations } from "../../core/i18n/Translations";
import { RenderConfig, ValidatedField } from "../../core/components/form/ValidatedField";
import { classNames } from "primereact/utils";
import {
	AddressValidationHelper,
	EfnValidationHelper,
} from "../../signup/helpers/countryRelatedHelpers";
import { DefaultTextFieldComponent } from "../../core/components/form/DefaultTextInput";
import { SpecialityAutoCompleteTextInput } from "../../core/components/form/SpecialityAutoCompleteTextInput";
import {
	getAvailableSpecialitiesList,
	OmittedInfectopharmRoles,
} from "../../signup/helpers/specialityHelper";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
	RoleApplicationRegistrationForm_UserInformationFragment$key
} from "../../../../__generated__/RoleApplicationRegistrationForm_UserInformationFragment.graphql";
import { Button } from "primereact/button";
import { ContactDetailsFormState } from "./ContactDetailsFormPart";
import { NewsletterFormPart, NewsletterFormState } from "./NewsletterFormPart";
import {
	RoleApplicationRegistrationForm_ApplyRoleMutation
} from "../../../../__generated__/RoleApplicationRegistrationForm_ApplyRoleMutation.graphql";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AddressFormState } from "./AddressFormPart";
import { isTargetRoleWithSpeciality } from "../../../helpers/isTargetRoleWithSpeciality";
import { isRoleAndLocationEFNCompliant } from "../../../helpers/isRoleAndLocationEFNCompliant";
import { showAvailableRolesBasedOnCountry } from "../../signup/steps/PersonalAndProfessionalDataStep";

const USER_STATUS_FRAGMENT = graphql`
    fragment RoleApplicationRegistrationForm_UserInformationFragment on UserInformation {
        signedUpOverBrand {
            icon {
                url
            }
            availableRoles
            id
            brandColorHex
            ...NewsletterFormPart_BrandFragment
        }
        ...NewsletterFormPart_UserInformationFragment
    }
`;

const MUTATION = graphql`
    mutation RoleApplicationRegistrationForm_ApplyRoleMutation($input: ApplyForRoleInput!) {
        Roles {
            applyForRole(input: $input) {
                userStatus {
                    signedUpOverBrand {
                        id
                    }
                    ...DashboardScreen_UserStatusFragment
                }
            }
        }
    }
`;

type FormState = ContactDetailsFormState &
	AddressFormState &
	NewsletterFormState & {
	efnNumber?: string;
	fachrichtung?: string;
	targetRole?: InfectopharmRole;
};

interface OwnProps {
	applicationToken?: string;
	userInformationFragment: RoleApplicationRegistrationForm_UserInformationFragment$key;
}

const PhoneOrFaxRegEx = /^[+][1-9]{2,3}[0-9]{3}[0-9]{4,9}$/;
const InvalidPhoneOrFaxMessage =
	"Bitte im internationalen Format und ohne Leerzeichen angeben z.B. +49...";

export const RoleApplicationRegistrationForm = ({
	                                                applicationToken,
	                                                userInformationFragment,
                                                }: OwnProps) => {
	const userInformation =
		useFragment<RoleApplicationRegistrationForm_UserInformationFragment$key>(
			USER_STATUS_FRAGMENT,
			userInformationFragment,
		);
	const [applyForRole, isApplyingForRole] =
		useMutation<RoleApplicationRegistrationForm_ApplyRoleMutation>(MUTATION);

	const navigate = useNavigate();
	const isPaedia = userInformation.signedUpOverBrand?.id === process.env.REACT_APP_PAEDIA_ID;

	const formik = useFormik<FormState>({
		initialValues: {
			company: "",
			phone: "",
			fax: "",
			efnNumber: "",
			street: "",
			postalCode: "",
			city: "",
			country: "Deutschland",
			fachrichtung: "",
			subscribedNewsletterRefs: [],
		},
		validationSchema: Yup.object().shape({
			...AddressValidationHelper,
			targetRole: Yup.string()
				/*.when("country", {
					is: (country: string) => {
						return country == "Oesterreich" && isPaedia;
					},
					then: Yup.string().test(
						"test-values-array",
						"Beruf wird benötigt",
						(targetRole) => targetRole == "PTA" || targetRole == "Midwife",
					),
				})*/
				.required("Beruf wird benötigt"),
			phone: Yup.string()
				.matches(PhoneOrFaxRegEx, InvalidPhoneOrFaxMessage)
				.required("Telefonnummer wird benötigt"),
			fax: Yup.string().matches(PhoneOrFaxRegEx, InvalidPhoneOrFaxMessage),
			efnNumber: EfnValidationHelper,
			fachrichtung: Yup.string().when("targetRole", {
				is: (targetRole: InfectopharmRole) => {
					return isTargetRoleWithSpeciality(targetRole);
				},
				then: Yup.string().required("Das Feld Fachrichtung wird benötigt.").nullable(true),
			}),
		}),
		onSubmit: (values) => {
			applyForRole({
				variables: {
					input: {
						applicationToken: applicationToken,
						efnNumber: isRoleAndLocationEFNCompliant(
							formik.values.targetRole,
							formik.values.country,
						)
							? values.efnNumber || undefined
							: undefined,
						targetRole: values.targetRole!,
						contactDetails: {
							faxNumber: values.fax,
							phoneNumber: values.phone,
						},
						practiceAddress: {
							company: values.company,
							street: values.street!,
							postalCode: values.postalCode!,
							city: values.city!,
							country: values.country!,
						},
						fachrichtung: isTargetRoleWithSpeciality(values.targetRole)
							? values.fachrichtung
							: undefined,
						subscribedNewsletterRefs: [...(values.subscribedNewsletterRefs || [])],
					},
				},
				onCompleted: () => {
					toast("Rolle erfolgreich beantragt.", {type: "success"});
					navigate(`/login/${userInformation.signedUpOverBrand?.id}`);
				},
			});
		},
	});

	const resetFachrichtung = () => {
		formik.setFieldValue("fachrichtung", "");
	};

	return (
		<div>
			<h1>Registrierung abschließen</h1>

			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"country"}
					label={"Land"}
					iconClass={"pi-street"}
					formikConfig={formik}
					component={({fieldValue, updateField, fieldName, isValid}) => {
						return (
							<div>
								<Dropdown
									name={fieldName}
									value={fieldValue}
									onChange={(e) => {
										resetFachrichtung();
										updateField(e.value);
									}}
									options={[
										{
											value: "Deutschland",
											label: "Deutschland",
										},
										{
											value: "Oesterreich",
											label: "Österreich",
										},
									]}
									className={classNames({
										"p-invalid": !isValid,
									})}
								/>
							</div>
						);
					}}
				/>
				<ValidatedField<FormState, string>
					name={"targetRole"}
					label={"Ich bin ein"}
					required={true}
					className="mb-3"
					formikConfig={formik}
					component={({fieldValue, fieldName, updateField, isValid}) => {
						return (
							<Dropdown
								name={fieldName}
								value={fieldValue}
								onChange={(e) => {
									resetFachrichtung();
									updateField(e.value);
								}}
								className={classNames({"p-invalid": !isValid})}
								options={(userInformation.signedUpOverBrand?.availableRoles!
										? showAvailableRolesBasedOnCountry(
											userInformation.signedUpOverBrand?.availableRoles!,
											formik.values.country || "Deutschland",
										)
										: ["Apothecary", "Doctor", "Midwife", "PTA", "PKA"]
								)
									.map((role) => {
										return {
											value: role,
											label: RoleTranslations[role] || role,
										};
									})
									.sort((a, b) => a.label.localeCompare(b.label))
									.concat({value: "Other", label: RoleTranslations["Other"]})}
							/>
						);
					}}
				/>

				{formik.values.targetRole === "Other" ? (
					<>
						<Message
							className="p-mb-2"
							severity="error"
							text={`Lieber Interessent, es tut uns leid, wir können für Ihren Fachkreis keine speziellen Inhalte zur Verfügung stellen. Aus diesem Grund ist eine Registrierung leider nicht möglich. Sollten Sie Fragen an uns haben, kontaktieren Sie uns bitte unter kontakt@infectopharm.com`}
						/>
					</>
				) : (
					<>
						{isRoleAndLocationEFNCompliant(
							formik.values.targetRole,
							formik.values.country,
						) && (
							<ValidatedField<FormState, string>
								name={"efnNumber"}
								label={`${
									formik.values.country == "Oesterreich"
										? "Österreichische Ärztekammer-Nummer (ÖÄK-Arztnummer)"
										: "Einheitliche Fortbildungs-Nummer (EFN)"
								}`}
								iconClass={"pi-user"}
								formikConfig={formik}
								required={false}
								component={DefaultTextFieldComponent}
							/>
						)}

						{isTargetRoleWithSpeciality(formik.values.targetRole) && (
							<ValidatedField<FormState, string>
								name={"fachrichtung"}
								label={"Fachrichtung"}
								required={isTargetRoleWithSpeciality(formik.values.targetRole)}
								formikConfig={formik}
								component={(renderConfig: RenderConfig<string>) => (
									<SpecialityAutoCompleteTextInput
										brandColor={
											userInformation?.signedUpOverBrand?.brandColorHex
										}
										items={getAvailableSpecialitiesList(
											formik.values.country ?? "Deutschland",
											(formik.values
												.targetRole as OmittedInfectopharmRoles) ??
											"Unknown",
										)}
										renderConfig={renderConfig}
									/>
								)}
							/>
						)}

						{formik.values.targetRole && (
							<>
								<h4 className="mt-6">Dienstliche Anschrift</h4>

								<ValidatedField<FormState, string>
									name={"company"}
									label={"Praxis / Institution"}
									iconClass={"pi-street"}
									formikConfig={formik}
									preventTrimStart={true}
									required={true}
									component={DefaultTextFieldComponent}
								/>
								<ValidatedField<FormState, string>
									name={"street"}
									label={"Straße und Hausnummer"}
									iconClass={"pi-street"}
									formikConfig={formik}
									required={true}
									preventTrimStart={true}
									component={DefaultTextFieldComponent}
								/>
								<ValidatedField<FormState, string>
									name={"postalCode"}
									label={"PLZ"}
									iconClass={"pi-street"}
									formikConfig={formik}
									required={true}
									preventTrimStart={true}
									component={DefaultTextFieldComponent}
								/>
								<ValidatedField<FormState, string>
									name={"city"}
									label={"Stadt"}
									iconClass={"pi-street"}
									formikConfig={formik}
									required={true}
									preventTrimStart={true}
									component={DefaultTextFieldComponent}
								/>
								<h4 className="mt-6">Dienstliche Kontaktdaten</h4>
								<ValidatedField<FormState, string>
									name={"phone"}
									label={"Telefonnummer"}
									iconClass={"pi-phone"}
									formikConfig={formik}
									required={true}
									component={DefaultTextFieldComponent}
								/>
								<ValidatedField<FormState, string>
									name={"fax"}
									label={"Faxnummer"}
									iconClass={"pi-fax"}
									formikConfig={formik}
									component={DefaultTextFieldComponent}
								/>
							</>
						)}

						<NewsletterFormPart
							userInformationFragmentRef={userInformation}
							country={formik.values.country!}
							brandFragmentRef={userInformation.signedUpOverBrand}
							role={formik.values.targetRole!}
							formik={formik}
						/>

						<Button
							disabled={isApplyingForRole}
							type="submit"
							label="Speichern & Weiter"
							className="p-mt-2 mt-4 text-xs"
						/>
					</>
				)}
			</form>
		</div>
	);
};
